// @flow
import { graphql, Link } from 'gatsby';
import React from 'react';

import Feed from '../components/Feed';
import Layout from '../components/Layout';
import Page from '../components/Page';
import Pagination from '../components/Pagination';
import Sidebar from '../components/Sidebar';
import TemplateWrapper from '../components/TemplateWrapper';
import { tagPagePath } from '../utils/page-paths';

type Props = {|
  +data: Object,
  +location: Object,
  +pageContext: Object,
|};

const metaDescriptions = {
  'Machine Learning': 'Machine Learning is, put simply, getting computers to generalize from examples. And that\'s what I try to do: put things simply. My posts on Machine Learning (ML) consist primarily of beginner-focused introductions to common ML models or concepts, and I strive to make my guides as clear and beginner-friendly as possible.',
  'Biotech': 'I originally got my start in biotech. More specifically, I spent much of my education and early career obsessed with understanding the biological underpinings of aging. As a result I tried to understand as much as I could about synthetic biology, cancer biology, stem cell biology, and pharmacology in hopes of figuring out a path towards treating aging. Since something like this is unlikely to be achieved by a single individual, I\'ve taken it upon myself to expose these ideas and concepts to the greater tech and biotech community (and hopefully do so as clear and practically-focused as possible).',
  'Blockchain': 'I bought my first Bitcoins off of a guy on Craigslist back in 2012. Since then I\'ve been fascinated with the prospects of what Blockchain technology (or BlockDAG, or distributed ledger technologies) can do for the world. Having worked at a few companies making use of these technologies, I\'ve tried writing a few pieces on the topic with as much practical value as possible.',
  'Dogs': 'There are plenty of posts on my blog that are there because I think they have some practical value to society at large. And then there are the projects I get involved with because the provide an excuse to look at pictures of cute dogs. In fact, my Instagram page is really just an archive of all the cute dogs I run into when I\'m out and about.'
};

const tagDescriptions = {
  'Machine Learning': (
    <>
      <p>
        In a nutshell, Machine Learning is a way to give computers the powers of generalization. And that's
        what I try to do: <b>put [seemingly complicated] things simply</b>. My posts on Machine
        Learning (ML) focus mainly on recent cutting edge research, and how to make it accessible to 
        everyone. I felt like many academic guides weren't accessible enough, so I strove to make
        my guides <b>as clear and practically-focused as possible</b>.
      </p>
      <p style={{ marginBottom: 0 }}>
        Unsure where to start? Here are some of my best / most popular posts:
      </p>
      <ul>
        <li>
          <Link to="/blog/lessons-from-becoming-a-machine-learning-engineer-in-12-months-without-a-cs-math-degree/">
            Lessons from becoming a Machine Learning Engineer in 12 months, without a CS or Math degree
          </Link>
        </li>
        <li>
          <Link to="/blog/how-to-approach-algorithm-problems/">How to approach algorithm problems</Link>
        </li>
        <li>
          <Link to="/blog/an-introduction-to-probabilistic-programming-now-available-in-tensorflow-probability/">An introduction to probabilistic programming, now available in TensorFlow Probability</Link>
        </li>
      </ul>
      <p>
        Similar tags include <Link to="/tag/tensorflow/">Tensorflow</Link>,{' '}
        <Link to="/tag/ga-ns/">GANs</Link>, and{' '}
        <Link to="/tag/private-machine-learning/">Private Machine Learning</Link>.
      </p>
      <p>Enjoy!</p>
    </>
  ),
  'Biotech': (
    <>
      <p>
        I originally got my start in biotech. More specifically, I spent much of my education and 
        early career obsessed with understanding the biological underpinings of aging. As a result
        I tried to understand as much as I could about synthetic biology, cancer biology, stem
        cell biology, and pharmacology in hopes of figuring out a path towards treating aging. Since
        Something like this is unlikely to be achieved by a single individual, I've taken it upon myself
        to expose these ideas and concepts to the greater tech and biotech community (and hopefully do so
        <b>as clear and practically-focused as possible</b>).
      </p>
      <p style={{ marginBottom: 0 }}>
        Here are some of my best / most popular posts:
      </p>
      <ul>
        <li>
          <Link to="/blog/under-investigated-fields/">
            Under-Investigated Fields List (Version 1.0)
          </Link>
        </li>
        <li>
          <Link to="/blog/my-experience-with-forever-labs/">My Experience with Forever Labs</Link>
        </li>
        <li>
          <Link to="/blog/lessons-learned-from-shutting-down-my-anti-aging-company/">100 Lessons Learned from Shutting down my Anti-Aging company</Link>
        </li>
      </ul>
      <p>Enjoy!</p>
    </>
  ),
  'Blockchain': (
    <>
      <p>
        I bought my first Bitcoins off of a guy on Craigslist back in 2012. Since then I've been
        fascinated with the prospects of what Blockchain technology (or BlockDAG, or distributed
        ledger technologies) can do for the world. Having worked at a few companies making use of
        these technologies, I've tried writing a few pieces on the topic with as much practical 
        value as possible.
      </p>
      <p style={{ marginBottom: 0 }}>
        Here are some of my best / most popular posts:
      </p>
      <ul>
        <li>
          <Link to="/blog/private-machine-learning-explained/">
            Private Machine Learning (Explained in 5 levels of complexity)
          </Link>
        </li>
        <li>
          <Link to="/blog/the-breakdown-of-social-medias-sociology-model/">The breakdown of social media's sociology model</Link>
        </li>
        <li>
          <Link to="/blog/bearcoin-a-protocol-implementation/">Bearcoin protocol implementation</Link>
        </li>
      </ul>
      <p>Enjoy!</p>
    </>
  ),
  'Dogs': (
    <>
      <p>
        There are plenty of posts on my blog that are there because I think they have some practical
        value to society at large. And then there are the projects I get involved with because the provide
        an excuse to look at pictures of cute dogs. In fact, my Instagram page is really just an archive of
        all the cute dogs I run into when I'm out and about.
      </p>
      <p style={{ marginBottom: 0 }}>
        Here are some of my best / most popular posts:
      </p>
      <ul>
        <li>
          <Link to="/blog/turning-my-coworkers-chihuahua-into-a-bear/">
            Turning my Coworker's Chihuahua into a Bear
          </Link>
        </li>
      </ul>
      <p>Enjoy!</p>
    </>
  )
};
// 0 if guest author, 1 otherwise
function edgeHasGuestAuthorValue(e) {
  return e.node.frontmatter.guestAuthor ? 0 : 1;
}

const TagTemplate = ({ data, pageContext, location }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = data.site.siteMetadata;

  const { tag, tagSlug, currentPage, hasPrevPage, hasNextPage, numPages } = pageContext;

  const { edges } = data.allMarkdownRemark;
  const pageTitle =
    currentPage > 1
      ? `${tag} Articles - Page ${currentPage} - ${siteTitle}`
      : `${tag} Articles - ${siteTitle}`;

  // Sort guest posts to back of this tag page
  edges.sort((a, b) => edgeHasGuestAuthorValue(b) - edgeHasGuestAuthorValue(a));

  return (
    <TemplateWrapper>
      <Layout title={pageTitle} description={metaDescriptions[tag] || siteSubtitle}>
        <Sidebar location={location} />
        <Page
          title={`${tag}${currentPage > 1 ? ` - Page ${currentPage}` : ''}`}
          subtitle={<Link to="/tags/">← Back to All Tags</Link>}
          description={tagDescriptions[tag]}
        >
          <Feed edges={edges} />
          <Pagination
            currentPage={currentPage}
            pagePath={tagPagePath.bind(null, tagSlug)}
            hasPrevPage={hasPrevPage}
            hasNextPage={hasNextPage}
            numPages={numPages}
          />
        </Page>
      </Layout>
    </TemplateWrapper>
  );
};

export const query = graphql`
  query TagPage($tag: String, $postsLimit: Int!, $postsOffset: Int!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allMarkdownRemark(
      limit: $postsLimit
      skip: $postsOffset
      filter: {
        frontmatter: { tags: { in: [$tag] }, template: { eq: "post" }, draft: { ne: true } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        ...FeedFragment
      }
    }
  }
`;

export default TagTemplate;
